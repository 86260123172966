/**
 * Fetches image and returns promise after fetch.
 *
 * @param {string} imageUrl -  Image SRC to fetch
 * @return {Promise} Returns promise of imageUrl that was successful
 */
export default function fetchImage(imageUrl) {
  return new Promise((resolve, reject) => {
    if (imageUrl == null) {
      reject('Image is null or undefined', imageUrl);
      return;
    }
    // Create New Image
    const downloadImage = new Image();
    downloadImage.onload = () => {
      // Resolve and return imageUrl once loaded.
      resolve(imageUrl);
    };

    downloadImage.onerror = (err) => {
      // Reject and return error and imageUrl if error.
      reject(err, imageUrl);
    };

    // Start the Download.
    downloadImage.src = imageUrl;
  });
}