import React from 'react';
import './shipping.css'


export default function Shipping({ form, dispatch }) {
  return (
    <div className="shipping">
      <h3>Address</h3>
      <br />
      <label>
        Name
        <input required autoComplete="name" placeholder="Full Name" value={form.name} onChange={(ev) => { dispatch({ name: 'name', value: ev.target.value })}} />
      </label>
      <label>
        Address
        <input required placeholder="123 Any Street" autoComplete="shipping address-line1" value={form.line1} onChange={(ev) => { dispatch({ name: 'line1', value: ev.target.value })}} />
      </label>
      <label>
        Unit
        <input autoComplete="shipping address-line2" value={form.line2} onChange={(ev) => { dispatch({ name: 'line2', value: ev.target.value })}} />
      </label>
      <label>
        City
        <input required name="city" value={form.city} onChange={(ev) => { dispatch({ name: 'city', value: ev.target.value })}} />
      </label>
      <label>
        State
        <input required name="state" value={form.state} onChange={(ev) => { dispatch({ name: 'state', value: ev.target.value })}} />
      </label>
      <label>
        Zip
        <input required name="zip" value={form.postal_code} onChange={(ev) => { dispatch({ name: 'postal_code', value: ev.target.value })}} />
      </label>
    </div>
  )
}