import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Othernawmanlogo from '../Assets/Othernawmanlogo.png';
import { connect } from 'react-redux';
import { updateCart } from '../ducks/reducer';
import { getCart } from '../utils/cart'

class Header extends Component {
  state = {
    navToggled: false
  }
  componentDidMount() {
    this.getCart()
  }
  getCart() {
    getCart()
      .then((cart) => {
        this.props.updateCart(cart)
      })
  }
  render() {
    return (
      <header className='shop-header'>
        <Link to='/shop/all'> <img src={Othernawmanlogo} alt='naw man' className='nawmanlogo' /></Link>
        {this.props.cart.length !== 0 ? (
          <div className='cartvector'>
            <Link to='/cart'>Cart {this.props.cart.length}</Link>
          </div>
        ) : null}
        {/* <div onClick={() => this.setState({ navToggled: !this.state.navToggled })} className="hamburger" >
          <div className={this.state.navToggled ? 'one' : null}></div>
          <div className={this.state.navToggled ? 'two' : null}></div>
          <div className={this.state.navToggled ? 'three' : null}></div>
        </div> */}
      </header>
    )
  }
}

const mapStateToProps = (state) => {
  const { cart } = state;
  return {
    cart

  }
}

export default connect(mapStateToProps, { updateCart })(Header)