import React, { useEffect, useState } from 'react';
import once from 'once';
import axios from 'axios';
import Header from './Components/Header';
import Nav from './Components/Nav';
import routes from './routes'
import context from './config/context';
import fetchImage from './utils/fetchImage';
import './App.css';
import './reset.css';

const fetchProducts = once(() => {
  return axios.get('/.netlify/functions/products')
})

export default function App() {
  const [products, setProducts] = useState([]);

  useEffect(() => {
    fetchProducts()
      .then((res) => {
        const products = res.data.data
        setProducts(products);
        return products;
      })
      .then((products) => {
        // Pre fetch images, because they are large
        products.forEach((item) => {
          const firstImage = item.images[0]

          if (firstImage) fetchImage(firstImage);
        })
      })
  }, [])

  return (
    <context.Provider value={products}>
      <div className="App">
        <Header />
        <main className="main">
          <Nav />
          {routes}
        </main>
      </div>
    </context.Provider>
  );
}
