import localforage from 'localforage';

const cartKey = 'cart';

export async function getCart() {
  let cart = await localforage.getItem(cartKey);
  if (cart === null) {
    cart = []
    await localforage.setItem(cartKey, [])
  }
  return cart
}

export async function addToCart(item) {
  const cart = await getCart()
  cart.push(item);
  return localforage.setItem(cartKey, cart);
}

export async function removeFromCart(id) {
  const cart = await getCart();

  return localforage.setItem(
    cartKey,
    cart.filter((e, i) => i !== id)
  )
}

export function clearCart() {
  return localforage.removeItem(cartKey)
}