import React, { Component, useState, useMemo, useEffect } from 'react';
import {
  CardElement,
  injectStripe,
  Elements,
  PaymentRequestButtonElement,
} from 'react-stripe-elements';
import axios from 'axios';
import { updateCart } from '../../ducks/reducer';
import { stripePkKey } from '../../config/constants';


class CheckoutOld extends Component {
  render() {
    // return (
    //   <StripeCheckout
    //     name="NAWMAN"
    //     image="https://media.licdn.com/dms/image/C4D03AQFBwDAyjx--xA/profile-displayphoto-shrink_200_200/0?e=1566432000&v=beta&t=xr3U3v-M8ctTJfZRHGLoqjCLOCh5Jnm2UvZ_MPzkvQ0"
    //     token={this.onToken}
    //     stripeKey={stripePkKey}
    //     amount={this.props.cart_total * 100}
    //     billingAddress={true}
    //     shippingAddress={true}
    //     zipCode={true}
    //     triggerEvent="onClick"
    //   >
    //     <button className="bttn-small">
    //       Pay with card
    //     </button>
    //   </StripeCheckout>
    // )
    return 'yo'
  }
}

const options = {
  style: {
    base: {
      fontSize: '16px',
      color: '#424770',
      fontFamily: 'Open Sans, sans-serif',
      letterSpacing: '0.025em',
      '::placeholder': {
        color: '#aab7c4',
      },
    },
    invalid: {
      color: '#c23d4b',
    },
  }
}

function Checkout({ stripe, cart, total, disabled, form }) {
  const [error, setError] = useState(null);
  const [canMakePaymentReq, setCanMakePaymentReq] = useState(false);

  // const onToken = (token, args) => {
  //   axios.post('/.netlify/functions/charge', {
  //     token,
  //     total: this.props.cart_total,
  //     metadata: args,
  //   })
  //     .then(res => {
  //       let { id } = res.data
  //       this.props.paymentSuccess(id)
  //     })
  //     .catch(err => {
  //       console.log(err)
  //     })
  // }

  const onSubmit = (ev) => {
    ev.preventDefault();
  }

  const handleChange = ({ error }) => {
    if (error) {
      console.error(error)
      setError(error.message);
    }
  };

  // const paymentRequest = useMemo(() => {
  //   return stripe.paymentRequest({
  //     country: 'US',
  //     currency: 'usd',
  //     total: {
  //       label: 'Demo total',
  //       amount: 1000,
  //     },
  //     requestPayerName: true,
  //     requestPayerEmail: true,
  //     requestShipping: true,
  //     displayItems: [
  //       {
  //         label: 'Shipping',
  //         amount: total.shipping * 100,
  //       },
  //       {
  //         label: 'Tax',
  //         amount: total.tax * 100
  //       }
  //     ],
  //     shippingOptions: [
  //       {
  //         id: 'shipping',
  //         label: 'Shipping',
  //         amount: 500,
  //       },
  //     ],
  //   });
  // }, [stripe])

  // useEffect(() => {
  //   paymentRequest.canMakePayment().then((result) => {
  //     console.log('req', result)
  //     setCanMakePaymentReq(!!result)
  //   });
  // }, [paymentRequest])

  
  const handleClick = () => {
    const { name, ...address } = form;
    axios.post('/.netlify/functions/checkout', {
      cart,
      shipping: {
        name: name,
        address,
      }
    })
      .then(({ data }) => {
        console.log(data)
        stripe.redirectToCheckout({
          sessionId: data.id,
        }).then((result) => {
          // If `redirectToCheckout` fails due to a browser or network
          // error, display the localized error message to your customer
          // using `result.error.message`.
        });
      })
  }

  return (
    <Elements>
      <>
        {/* {canMakePaymentReq && (
          <PaymentRequestButtonElement
            paymentRequest={paymentRequest}
          />
        )} */}
        <button onClick={handleClick} type="button" className="pay-button" disabled={disabled}>Pay</button>
        {disabled && <div className="pay-button-alert">Please fill out your address</div>}
      </>
      {/* <div className="checkout">
        <form onSubmit={onSubmit}>
          {canMakePaymentReq && (
            <PaymentRequestButtonElement
              paymentRequest={paymentRequest}
            />
          )}
          <label>
            Card details
            <CardElement
              onChange={handleChange}
              {...options}
            />
          </label>
          <div className="error" role="alert">
            {error}
          </div>
          <button>Pay</button>
        </form>
      </div> */}
    </Elements>
  )
}

export default injectStripe(Checkout);