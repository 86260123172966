import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import { Link } from 'react-router-dom';
import vector from '../../Assets/Vector.svg';
import p from '../../Assets/placeholder.png';
import logo from '../../Assets/Othernawmanlogo.png'

export default class Home extends Component {
    handleScrollToElement(e) {
        const tesNode = ReactDOM.findDOMNode(this.refs[e])
        window.scrollTo({ top: tesNode.offsetTop, behavior: 'smooth' });
    }
    render() {
        return (
            <div className="App">
                <section className='home-top'>
                    <img src={logo} alt='nawman' />
                    <br />
                    <Link to='/shop/all'><button type='button' className='red-button'><p className='link-text'>SHOP</p></button></Link>
                </section>
                {/* <div className='about-rectangle'>
                    <div onClick={() => this.handleScrollToElement('about')} >
                        <p className='link-text'>ABOUT <img className='vector' src={vector} alt='about' /></p>
                    </div>
                </div>
                <section className='about' ref='about'>
                    <h2 className='link-text' id='dark'>ABOUT</h2>
                    <hr />
                    <div className='about-body'>
                        <img src={p} alt='Josif and Joon !Placeholder!' />
                        <p className='body-text'>Bacon ipsum dolor amet salami fatback spare ribs ball tip turkey. Ball tip shankle chicken filet mignon, corned beef burgdoggen strip steak tenderloin ham hock salami. Capicola filet mignon biltong shank, tenderloin rump pork strip steak tail cow. Landjaeger tail chuck beef ribs jerky. Pork belly ground round salami, alcatra pancetta burgdoggen chicken.<br /><br />

                            Buffalo swine pork loin ball tip, turkey kielbasa drumstick short ribs shank ham pig. Fatback doner pancetta leberkas kevin flank hamburger capicola. Ball tip salami turducken pancetta bacon cow ham corned beef rump cupim strip steak sausage pork loin picanha. Meatloaf cupim drumstick pork tenderloin short ribs.<br /><br />

                            Rump short loin sirloin, landjaeger pork loin sausage brisket tri-tip. Leberkas spare ribs beef ribs prosciutto rump. Corned beef turkey prosciutto strip steak tongue, pork burgdoggen picanha pig pork loin. Buffalo tongue ham hock cupim tri-tip chicken ham pastrami pork chop.<br /><br />

                            Ham ribeye picanha sausage venison t-bone. Turducken ribeye tri-tip, filet mignon shoulder shank boudin ham pork chop picanha kevin alcatra short ribs. Ribeye pork loin short ribs beef doner beef ribs pork pork chop, boudin t-bone sirloin chicken alcatra capicola. Swine flank rump meatloaf. Prosciutto jowl picanha tenderloin pork chop hamburger turkey. Flank corned beef pastrami tenderloin meatball sirloin venison tail sausage.<br /><br />

                            T-bone capicola pork loin pork belly shankle tongue chuck porchetta fatback venison meatball pork short ribs shoulder beef. Hamburger turducken alcatra pork pig, strip steak tongue short loin drumstick. Swine cow picanha frankfurter bresaola. Tri-tip boudin shankle swine bresaola hamburger, tongue cow. Jowl bresaola boudin burgdoggen leberkas turducken, sausage buffalo filet mignon cupim. Pancetta tail short loin prosciutto t-bone ground round, bacon jowl fatback porchetta ham hock strip steak. Sausage jerky ball tip, kielbasa pork chop prosciutto tail kevin venison turkey ground round meatball.<br /><br />

                            Does your lorem ipsum text long for something a little meatier? Give our generator a try… it’s tasty!</p>
                    </div>
                </section> */}

            </div>
        );
    }
}

