import React, { useContext, useMemo, useState } from 'react';
import { connect } from 'react-redux';
import { updateCart } from '../../ducks/reducer';
import { addToCart } from '../../utils/cart';
import context from '../../config/context';
import './product.css'


function Product({ match, cart, updateCart, history }) {
  const { id } = match.params;
  const [size, setSize] = useState('Small')
  const products = useContext(context);

  const product = useMemo(() => {
    const foundProduct = products.find((item) => item.id === id)

    if (foundProduct == null) return null;

    return {
      data: foundProduct,
      image: foundProduct.images[0],
      price: foundProduct.metadata.price,
      sizing: foundProduct.attributes.includes('size')
    }
  }, [id, products])

  const isInCart = cart.some((item) => item.id === id);

  if (product == null) return null;

  const { price, image, sizing, data } = product;

  function handleAddToCart(item) {
    if (sizing) item.metadata.size = size;
    addToCart(item)
      .then(cart => {
        updateCart(cart);
      })
  }

  return (
    <div className='product'>
      <div className='product-titles'>
        <h1>{data.name}</h1>
        <label>${price}</label>
      </div>
      <div className='image-container'>
        <img src={image} alt={data.name} />
      </div>
      <div className='add-form'>

        <div className='product-variants'>
          <div data-tap-disabled="true" className='select-wrapper'>
            {sizing ? (
              <select value={size} onChange={e => setSize(e.target.value)}>
                <option value='Small'>Small</option>
                <option value='Medium'>Medium</option>
                <option value='Large'>Large</option>
                <option value='X-Large'>X-Large</option>
              </select>
            ) : (
                <select>
                  <option value=''>One size fits all</option>
                </select>
              )
            }
          </div>
        </div>

        {isInCart
          ? <button type='' className='' onClick={() => history.push('/cart')}>IN CART</button>
          : <button type='button' onClick={() => handleAddToCart(data)}>ADD TO CART</button>
        }
      </div>
      <p>{data.description}</p>
    </div>
  )
}

const mapStateToProps = (state) => {
  const { cart } = state;
  return {
    cart
  }
}

export default connect(mapStateToProps, { updateCart })(Product)