import React, { useEffect } from 'react';
import { clearCart } from '../../utils/cart'

function Confirmation() {
  useEffect(() => {
    clearCart();
  }, [])

  return (
    <h1>Thanks for supporting!</h1>
  )
}

export default Confirmation