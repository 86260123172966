import React, { useEffect, useReducer } from 'react';
import {
  StripeProvider,
  Elements,
} from 'react-stripe-elements';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { updateCart } from '../../ducks/reducer';
import Checkout from './Checkout'
import Shipping from './Shipping';
import { getCart, removeFromCart, clearCart } from '../../utils/cart'
import { stripePkKey } from '../../config/constants';
import './cart.css'


const formatNumber = (number) => {
  return Number(number).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })
}


function Cart({ total: subtotal, cart, updateCart, history }) {
  const shipping = 5;
  const tax = Number(((subtotal + shipping) * 0.0485).toFixed(2));
  const total = subtotal + tax + shipping;
  console.log({ cart })

  useEffect(() => {
    getCart()
      .then(cart => {
        updateCart(cart)
      })
  }, [updateCart])

  function handleRemoveFromCart(id) {
    removeFromCart(id)
      .then(cart => {
        updateCart(cart);
      })
  }
  function paymentSuccess(id) {
    clearCart()
      .then(() => {
        updateCart([])
        history.push(`/confirmation/${id}`)
      });
  }

  const [form, dispatch] = useReducer((state, action) => {
    return {
      ...state,
      [action.name]: action.value,
    }
  }, {
    name: '',
    line1: '',
    line2: '',
    city: '',
    state: '',
    country: 'US',
    postal_code: '',
  })

  const isFormDisabled = Object.entries(form).some(([key, value]) => value === '' && key !== 'line2' );

  const mappedCart = cart.map((e, i) => {
    return (
      <div key={i} className='product-card'>
        <Link to={`/product/${e.id}`}>
          <img src={e.images[0]} alt={e.name} />
        </Link>
        <div className='product-info'>
          <h1 className='medium'>{e.name}</h1>
          <label className='low' >{e.size} ${e.metadata.price} </label>
          <br />
          <label className='low remove' onClick={() => handleRemoveFromCart(i)}>Remove</label>
        </div>
      </div>
    )
  })

  return (
    <StripeProvider apiKey={stripePkKey}>
      <div className='cart'>
        <h1 className='italic'>CART</h1>
        <br />
        <div className='product-container'>
          {mappedCart}
        </div>

        {cart.length > 0
          ? (
            <>
              <hr />
              <br />
              <div className="cart-items-wrapper">
                <Shipping dispatch={dispatch} form={form} />
                <div className="cart-price">
                  <p className='cart-total'>Subtotal: ${formatNumber(subtotal)}</p>
                  <p className='cart-total'>Shipping: ${formatNumber(shipping)}</p>
                  <p className='cart-total'>Tax: ${formatNumber(tax)}</p>
                  <p className='cart-total'><strong>Total: ${formatNumber(total)}</strong></p>
                  <Elements>
                    <Checkout paymentSuccess={paymentSuccess} cart={cart} total={{ shipping, tax, total }} disabled={isFormDisabled} form={form} />
                  </Elements>
                </div>
              </div>
            </>
          )
          : (
            <h2 className="no-products">No Products added yet.</h2>
          )}
      </div>
    </StripeProvider>
  )
}

const mapStateToProps = (state) => {
  const { cart, total } = state;
  return {
    cart,
    total
  }
}
export default connect(mapStateToProps, { updateCart })(Cart)