import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import context from '../../config/context';


export default function Shop({ match }) {
  const products = useContext(context);

  function filterProducts(e) {
    let { filter } = match.params;
    if (filter === 'all') {
      return true;
    } else {
      return (e.metadata.type.toLowerCase() === filter)
    }
  }

  return (
    <div className='shop'>
      <div className='product-container'>
        {products.filter(filterProducts).map((item) => {
          return (
            <div className='product-card' key={item.id}>
              <Link to={`/product/${item.id}`}>
                <img src={item.images[0]} alt={item.name} />
      
                <div className='product-info'>
                  <p>{item.name}</p>
                  <p>${item.metadata.price}</p>
                </div>
              </Link>
            </div>
          )
        })}
      </div>
    </div>
  )
}