import React from 'react';
import { Link, withRouter } from 'react-router-dom';

function Nav({ location }) {
  if (location.pathname === '/' || location.pathname === '/cart') return null;

  return (
    <nav className="nav">
      <Link to='/shop/all'>ALL</Link>
      <Link to='/shop/shirt'>T-SHIRTS</Link>
      <Link to='/shop/hoodie'>HOODIES</Link>
      <Link to='/shop/hat'>HATS</Link>
      <Link to='/cart'>CART</Link>
    </nav>
  )
}

export default withRouter(Nav)