import React from 'react';
import { Route, Switch } from 'react-router-dom'
import Home from './Components/Home/Home';
import Shop from './Components/Shop/Shop';
import Product from './Components/Product/Product';
import Cart from './Components/Cart/Cart';
import Confirmation from './Components/Cart/Confirmation'

export default (
  // <div className="content">
  <Switch>
    <Route path='/' exact component={Home} />
    <Route path='/shop/:filter' component={Shop} />
    <Route path='/product/:id' component={Product} />
    <Route path='/cart' component={Cart} />
    <Route path='/confirmation' component={Confirmation} />
  </Switch>
  // </div>
)