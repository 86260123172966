const initialState = {
    cart: [],
    total: 0
}

const UPDATE_CART = 'USER_CART'

export default function reducer(state=initialState, action) {
    switch (action.type) {
        case UPDATE_CART:
            return {
                ...state,
                cart: action.payload.cart,
                total: action.payload.total
            }
            
    
        default:
            return state
    }
    
}

export function updateCart(cart){
    let total = 0;
    cart.forEach(e => total += +e.metadata.price)
    return {
        type: UPDATE_CART,
        payload: {cart, total}
    }
}